<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reclamaciones')+': '+$t('Vehículo')}} </h3>
		</div>
		<div class="p-col-12" v-if="!mostrar">
			<div class="card">
                <DataTable :value="claims" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
                    <template #header>
                        <div class="table-header">
							<div>
								<Dropdown class="p-mr-2" v-model="year" :options="$store.state.years" optionLabel="nombre"></Dropdown>
								<Dropdown class="p-mr-2" v-model="mes" :options="$store.state.meses" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
								<Dropdown class="p-mr-2" v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
								<Button :disabled ="$store.state.loading" :label="$t('Buscar')" style="width: 110px;" icon="pi pi-search" class="p-button-primary p-mr-2" @click="Buscar()"/>
							</div>
							<div>{{ $t('Registros') }}: {{ claims.length }}</div>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
							<strong :style="'color:'+slotProps.data.color" v-if="slotProps.data.estado!=2 && i18n.locale() == 'es'" class="p-mr-2 p-mb-2">
								{{slotProps.data.estado_nombre}}
							</strong>
							<strong :style="'color:'+slotProps.data.color" v-if="slotProps.data.estado!=2 && i18n.locale() == 'en'" class="p-mr-2 p-mb-2">
								{{slotProps.data.estado_name}}
							</strong>
							<strong :style="'color:'+slotProps.data.color" v-if="slotProps.data.estado==2 && i18n.locale() == 'es'" class="p-mr-2 p-mb-2">
								{{slotProps.data.estado2_nombre}}
							</strong>
							<strong :style="'color:'+slotProps.data.color" v-if="slotProps.data.estado==2 && i18n.locale() == 'en'" class="p-mr-2 p-mb-2">
								{{slotProps.data.estado2_name}}
							</strong>
							<i class="pi pi-clock p-mr-2 p-mb-2" v-if="slotProps.data.migracion"/>
							<i class="pi pi-bell p-mr-2 p-mb-2" v-if="slotProps.data.edit_claim"/>
                        </template>
                    </Column>   
                    <Column field="codigo" :header="$t('Claim')+' #'" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                              
                        </template>
                    </Column>
                    <Column field="poliz" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.poliz}}                              
                        </template>
                    </Column>
                    <Column field="cliente" :header="$t('Cliente')+' #'" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.cliente}}                              
                        </template>
                    </Column>
                    <Column field="fecha" :header="$t('Fecha del incidente')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{convfech(slotProps.data.fecha)}}
                        </template>
                    </Column>
                    <Column field="forenc" :header="$t('Forence')" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{slotProps.data.forenc}}<br>
							<Tag class="p-mr-2" icon="pi pi-exclamation-triangle" severity="warning" :value="$t('Pendiente')" v-if="slotProps.data.forence"></Tag>
                        </template>
                    </Column>
                    <Column field="createdby" :header="$t('Creado por')" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{slotProps.data.createdby}}
                        </template>
                    </Column>
					<Column :header="$t('Acciones')" headerStyle="width: 80px">
						<template #body="slotProps">
							<Button :disabled ="$store.state.loading" icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="Editar(slotProps.data)"/>
						</template>
					</Column>
                </DataTable>
			</div>
        </div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Policy :datos="seleccion.poliz" :detall="seleccion.vehiculo"/>
		</div>
		<div class="p-col-12 p-md-6" v-if="mostrar">
			<Basic :datos="seleccion.poliz" :detall="seleccion.vehiculo"/>
		</div>
		<div class="p-col-12 p-md-6" v-if="mostrar">
			<Information :datos="seleccion.poliz" :detall="seleccion.vehiculo"/>
		</div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Accordion>
				<AccordionTab :header="$t('Beneficiario')">
					<Beneficiary :edit="true" :tipo_veh="tipos" :datos="seleccion.beneficiario"/>
				</AccordionTab>
			</Accordion>
		</div>	
		
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Accordion>
				<AccordionTab :header="$t('Forence')">
					<Detalle :datos="seleccion" :edit="true" :tipo_veh="tipos" :estados="seleccion.estado.code"/>
				</AccordionTab>
			</Accordion>
		</div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Estatus')}}:  
							<strong :style="'color:'+seleccion.color" v-if="seleccion.estado.code!=2 && i18n.locale() == 'es'">
								{{seleccion.estado_nombre}}
							</strong>
							<strong :style="'color:'+seleccion.color" v-if="seleccion.estado.code!=2 && i18n.locale() == 'en'">
								{{seleccion.estado_name}}
							</strong>
							<strong :style="'color:'+seleccion.color" v-if="seleccion.estado.code==2 && i18n.locale() == 'es'">
								{{estado2.nombre}}
							</strong>
							<strong :style="'color:'+seleccion.color" v-if="seleccion.estado.code==2 && i18n.locale() == 'en'">
								{{estado2.name}}
							</strong>
						</h4>
					</template>
					<template #content>	
						<div class="p-fluid p-formgrid p-grid">	
							<div class="p-field p-col-12">
								<div class="p-fluid p-formgrid p-grid">
									<div class="p-field p-col-3">
										<Checkbox v-model="seleccion.lesiones" :binary="true" :disabled="seleccion.estado.code>11"/>
										{{$t('Se reporta lesiones personales?')}} {{seleccion.lesmonto}}
									</div>
									<div class="p-field p-col-9">
										<Lesions :datos="seleccion" :edit="true"/>
									</div>
									<div class="p-field p-col-3">
										<Checkbox v-model="seleccion.reemplazo" :binary="true" :disabled="seleccion.estado.code>11"/>
										{{$t('Se requiero Vehículo de reemplazo?')}}
									</div>
									<div class="p-field p-col-9">
										<Replacement :datos="seleccion" :edit="true"/>
									</div>
									<div class="p-field p-col-12">
										<Checkbox v-model="seleccion.recuperacion" :binary="true" :disabled="seleccion.estado.code>11"/>
										{{$t('Aplica recuperación?')}}
									</div>
									<div class="p-field p-col-3" v-if="seleccion.estado.code==11 || seleccion.estado.code>=13">
										{{$t('Informe de Depreciación')}}
										<FileUpload :chooseLabel="$t('Subir PDF')" uploadLabel="Cargar" mode="basic" :name="pdf5" :url="url.api+'upload.php'"  accept="application/pdf" :maxFileSize="4000000" @upload="myUploader5(pdf5)" :auto="true" :disabled="seleccion.estado.code>11"/>
										<small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
									</div>
									<div class="p-field p-col-3" v-if="seleccion.estado.code==11 || seleccion.estado.code>=13">
										<img style="width: 75px;" src="images/pdf.jpg" v-if="seleccion.depreciacion" @click="VerPDF(seleccion.depreciacion)"/>
									</div>
									<div class="p-field p-col-3" v-if="seleccion.estado.code==11 || seleccion.estado.code>=13">
										{{$t('Pmt deducible')}}
										<FileUpload :chooseLabel="$t('Subir PDF')" uploadLabel="Cargar" mode="basic" :name="pdf6" :url="url.api+'upload.php'"  accept="application/pdf" :maxFileSize="4000000" @upload="myUploader6(pdf6)" :auto="true" :disabled="seleccion.estado.code>11"/>
										<small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
									</div>
									<div class="p-field p-col-3" v-if="seleccion.estado.code==11 || seleccion.estado.code>=13">
										<img style="width: 75px;" src="images/pdf.jpg" v-if="seleccion.deducible" @click="VerPDF(seleccion.deducible)"/>
									</div>
								</div>
							</div>
							<div class="p-field p-col-12">
								<div class="p-fluid p-formgrid p-grid">
									<div class="p-field p-col-6 p-grid">
										<div class="p-col-12">
											<strong>{{$t('Fecha en que ocurrió el incidente')}}: {{seleccion.fecha}}</strong>
										</div>
										<div class="p-col-12">
											<strong>{{$t('Monto de Reserva')}}: {{seleccion.estimacion}}</strong>
										</div>
										<div class="p-col-12" v-if="seleccion.estado.code==11 || seleccion.estado.code>=13">
											<strong>{{$t('Documento de Aceptación')}}</strong>
										</div>
										<div class="p-col-2">
											<Checkbox v-model="seleccion.taller_activo" :binary="true" :disabled="seleccion.estado.code>11"/>
											{{$t('Pago a Taller')}}
										</div>
										<div class="p-col-12" v-if="seleccion.estado.code==11 || seleccion.estado.code>=13">
											{{$t('Beneficiario')}}
											<FileUpload :chooseLabel="$t('Subir PDF')" uploadLabel="Cargar" mode="basic" :name="pdf" :url="url.api+'upload.php'"  accept="application/pdf" :maxFileSize="4000000" @upload="myUploader2(pdf)" :auto="true" :disabled="seleccion.estado.code>11"/>
											<small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
										</div>
										<div class="p-col-6" v-if="seleccion.estado.code==11 || seleccion.estado.code>=13">
											<img style="width: 75px;" src="images/pdf.jpg" v-if="seleccion.beneficiario.aceptacion" @click="VerPDF(seleccion.beneficiario.aceptacion)"/>
										</div>
									</div>	
									<div class="p-field p-col-6 p-grid">
										<div class="p-col-8">
											<label>{{$t('Forence')}}</label>
											<Dropdown id="state" v-model="seleccion.forence_user" :options="forences" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" :disabled="seleccion.estado.code>11"></Dropdown>
											<small v-show="!seleccion.forence_user" class="p-error">{{$t('required')}}</small>
										</div>
										<div class="p-col-4">
											{{$t('Notificar al forence')}}: <Checkbox v-model="seleccion.forence" :binary="true" :disabled="seleccion.estado.code>11"/>
										</div>
										<div class="p-col-12">
											<label>{{$t('Observaciones')}}: </label>
											<Textarea v-model="seleccion.observacion" rows="2" :disabled="seleccion.estado.code>11"/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
					<template #footer>
						<Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary p-mr-2 p-mb-2" @click="Guardar()" v-if="seleccion.estado.code<=11"/>
						<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger p-mr-2 p-mb-2" @click="mostrar = false"/>
						<Dropdown class="p-mr-2 p-mb-2" id="state" v-model="seleccion.estado" :options="estados.filter(val=>val.code<=11)" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" style="width:250px;"  v-if="seleccion.estado.code<=11"></Dropdown>
						<Dropdown class="p-mr-2 p-mb-2" id="state" v-model="seleccion.estado2" :options="estados2" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" style="width:250px;" v-if="seleccion.estado.code==2"></Dropdown>
					</template>
					
				</Card>
			</div>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Reclamos','Claim2');
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	import url from "@/service/_URL";
	import PDF from "../service/PDF";
	import { defineAsyncComponent } from "@vue/runtime-core";

	export default {
		components: {
			Policy: defineAsyncComponent(() => import('../comp_claim/Policy.vue')),
			Basic: defineAsyncComponent(() => import('../comp_claim/Basic.vue')),
			Information: defineAsyncComponent(() => import('../comp_claim/Information.vue')),
			Beneficiary: defineAsyncComponent(() => import('../comp_claim/Beneficiary.vue')),
			Detalle: defineAsyncComponent(() => import('../comp_claim/Detalle.vue')),
			Lesions: defineAsyncComponent(() => import('../comp_claim/Lesions.vue')),
			Replacement: defineAsyncComponent(() => import('../comp_claim/Replacement.vue')),
		},
		data() {
			return {
				year: null,
				mes: null,
				nuevo: false,
				buscar: null,
				opcion: {nombre: 'Todos', name: 'All', code: -1, color: ''},
				opciones: [{nombre: 'Todos', name: 'All', code: -1, color: ''}],
				insert: false,
				edit: false,
				del: false,
				poliza: null,
				filters: {},
				selectedCustomers: null,
				submitted: false,
				size: '60vw',
				seleccion: {},
				mostrar: false,
				forences: [],
				tipos: [],
				estados: [],
				estados2: [],
				anexos2: [],
				descripcion: null,
				validationErrors: {},
				claims: [],
				pdf: null,
				img: null,
				pdf2: null,
				lpdf: null,
				imegan: null,
				pdf5: null,
				pdf6: null,
				fecha1: null,
				fecha2: null,
			}
		},
		created() {
			this.year = this.$store.state.year;
			this.mes = this.$store.state.mes;
			this.url = url;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.i18n = useI18nPlugin();
			const tokgen = new Token(256);
			this.pdf = tokgen.generate();
			this.lpdf = tokgen.generate();
			this.img = tokgen.generate();
			this.pdf2 = tokgen.generate();
			this.pdf5 = tokgen.generate();
			this.pdf6 = tokgen.generate();
			this.fecha1 = Consulta.fstring(this.$store.state.fecha);
			this.fecha2 = Consulta.fstring(this.$store.state.fecha);
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.mostrar = false;
				Consulta.Procesar('Cons_Eval',{
					id: 0,
				}).then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.fecha1 = response.result.fecha;
					this.forences = response.result.forences;
					this.tipos = response.result.tipos;
					this.estados = response.result.estados;
					this.estados2 = response.result.estados2;
					this.estado2 = this.estados2[0];
					response.result.opciones.forEach(element => {
						this.opciones.push(element);
					});
				}); 
			},
			Buscar(){
				if(this.mes && this.year && this.opcion){
					this.mostrar = false;
					this.seleccion = {};
					this.claims = [];
					Consulta.Procesar('BuscarEval',{
						opcion: this.opcion.code,
						mes: this.mes.code,
						year: this.year.code,
						}).then(response => {
							//this.$store.state.error = response;
							this.claims = response.result;
						});
				}
			},
			Ver(data){
				const doc = new PDF();
				doc.Aceptacion(this.seleccion,data,2);
			},
			Editar(data) {
				this.seleccion = {};
				Consulta.Procesar('Detalle',{
					claim: data,
					}).then(response => {
						//this.$store.state.error = response;
						var selec = {...data};
						selec.poliz = response.result.poliz;
						selec.vehiculo = response.result.vehiculo;
						selec.anexos = response.result.anexos;
						selec.foren = response.result.foren;
						this.$store.state.claim.lension = response.result.lesion;
						selec.beneficiario = response.result.beneficiario;
						selec.forence_user = this.forences.filter(val => val.code == selec.forence_user)[0];
						selec.estado = this.estados.filter(val => val.code == selec.estado)[0];
						selec.estados2 = this.estados2.filter(val => val.code == selec.estados2)[0];
						this.seleccion = selec;
						this.mostrar = true;
					});
			},
			Guardar() {
				if (this.validateForm()) {	
					this.seleccion.lesion = this.$store.state.claim.lension;
					Consulta.Procesar('Evaluacion',{
						claim: this.seleccion,
					}).then(response => {
						//this.$store.state.error = response;
						if (response.result) {
							this.$toast.add({
								severity:'success',
								summary: this.$t('Éxito'), 
								detail: this.$t('Información Procesada'), 
								life: 3000
							});
							this.$store.commit('Loading');
							this.Buscar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: this.$t('Advertencia'), 
								detail: this.$t('Error'), 
								life: 3000
							});
						}
					});
				}
			},
			VerPDF(document){
				window.open(url.upload+document+'.pdf', '_blank');
			},
			myUploader2(pdf) {
				Consulta.Procesar('RevPDF',{
					foto: pdf,
				}).then(response => {
					if(response.result){	
						this.seleccion.beneficiario.aceptacion = pdf;
						const tokgen = new Token(256);
						this.pdf = tokgen.generate();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error en Foto'), 
							life: 3000
						});
					}
				});
			},
			myUploader5(pdf) {
				Consulta.Procesar('RevPDF',{
					foto: pdf,
				}).then(response => {
					//this.$store.state.error = response;
					if(response.result){	
						this.seleccion.depreciacion = pdf;
						const tokgen = new Token(256);
						this.pdf5 = tokgen.generate();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error en Foto'), 
							life: 3000
						});
					}
				});
			},
			myUploader6(pdf) {
				Consulta.Procesar('RevPDF',{
					foto: pdf,
				}).then(response => {
					if(response.result){	
						this.seleccion.deducible = pdf;
						const tokgen = new Token(256);
						this.pdf6 = tokgen.generate();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error en Foto'), 
							life: 3000
						});
					}
				});
			},
			validateForm() {
				if (this.seleccion.fecha==null)
				this.validationErrors['fecha'] = true;
				else
				delete this.validationErrors['fecha'];
				if (this.seleccion.reserva==null)
				this.validationErrors['reserva'] = true;
				else
				delete this.validationErrors['reserva'];
				if (this.seleccion.forence_user==null)
				this.validationErrors['forence'] = true;
				else
				delete this.validationErrors['forence'];

				return !Object.keys(this.validationErrors).length;
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
